import React, {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { DashboardSpaceGroupWrapper } from '../pages/dashboardSpaceGroup/DashboardSpaceGroupWrapper'
import { DashboardSpaceWrapper } from '../pages/dashboardSpace/DashboardSpaceWrapper'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/users/UsersPage'))
  const UsersFrontPage = lazy(() => import('../modules/users-Front/UsersFrontPage'))
  const ClientsPage = lazy(() => import('../modules/clients/ClientsPage'))
  const ClientsHistoryTemplatesPage = lazy(
    () => import('../modules/clients-history-template/ClientsHistoryTemplatesPage')
  )
  const ClientsSegmentsPage = lazy(() => import('../modules/clients-segments/ClientsSegmentsPage'))
  const SpacePage = lazy(() => import('../modules/spaces/SpacePage'))
  const CountryPage = lazy(() => import('../modules/country/CountryPage'))
  const CountryTranslationsPage = lazy(
    () => import('../modules/country-translations/CountryTranslationsPage')
  )
  const EditSpacepage = lazy(() => import('../modules/spaces/edit-space/EditSpacepage'))
  const AppConfigspage = lazy(() => import('../modules/spaces/app-configs/AppConfigspage'))
  const EditSocialMediaSpacepage = lazy(
    () => import('../modules/spaces/social-media-space/EditSocialMediaSpacepage')
  )
  const GalleriesPage = lazy(() => import('../modules/spaces/galleries-space/GalleriesPage'))
  const PortefolioPage = lazy(() => import('../modules/spaces/portfolio-space/PortefolioPage'))
  const VouchersPage = lazy(() => import('../modules/vouchers/VouchersPage'))
  const CampaignsPage = lazy(() => import('../modules/campaigns/CampaignsPage'))
  const EmailConfigsPage = lazy(() => import('../modules/email-configs/EmailConfigsPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const EmailsSendedPage = lazy(() => import('../modules/sended-emails/EmailsSendedPage'))
  const NotificationTemplate = lazy(
    () => import('../modules/notification-template/NotificationTemplatePage')
  )
  const SmsTemplatePage = lazy(() => import('../modules/sms-template/SmsTemplatePage'))
  const BillingPage = lazy(() => import('../modules/billing/BillingPage'))
  const CaixaPage = lazy(() => import('../modules/billing/CaixaPage'))
  const CodesPage = lazy(() => import('../modules/codes/CodesPage'))
  const StatisticsPage = lazy(() => import('../modules/statistics/StatisticsPage'))
  const GoalsPage = lazy(() => import('../modules/goals/GoalsPage'))

  const CodesConfigsPage = lazy(
    () => import('../modules/codes-configs/CodesConfigsPage')
  )
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='dashboardSpaceGroup' element={<DashboardSpaceGroupWrapper />} />
        <Route path='dashboardSpace' element={<DashboardSpaceWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='spaces/*'
          element={
            <SuspensedView>
              <SpacePage />
            </SuspensedView>
          }
        />
        <Route
          path='billing/*'
          element={
            <SuspensedView>
              <BillingPage />
            </SuspensedView>
          }
        />
        <Route
          path='caixa/*'
          element={
            <SuspensedView>
              <CaixaPage />
            </SuspensedView>
          }
        />
        <Route
          path='editSpace/*'
          element={
            <SuspensedView>
              <EditSpacepage />
            </SuspensedView>
          }
        />
        <Route
          path='appconfigs/*'
          element={
            <SuspensedView>
              <AppConfigspage />
            </SuspensedView>
          }
        />
        <Route
          path='statistics/*'
          element={
            <SuspensedView>
              <StatisticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='goals/*'
          element={
            <SuspensedView>
              <GoalsPage />
            </SuspensedView>
          }
        />
        <Route
          path='editSocialMediaSpace/*'
          element={
            <SuspensedView>
              <EditSocialMediaSpacepage />
            </SuspensedView>
          }
        /> <Route
          path='codes/configs/*'
          element={
            <SuspensedView>
              <CodesConfigsPage />
            </SuspensedView>
          }
        />
        <Route
          path='galleriesPage/*'
          element={
            <SuspensedView>
              <GalleriesPage />
            </SuspensedView>
          }
        />

        <Route
          path='portefolioPage/*'
          element={
            <SuspensedView>
              <PortefolioPage />
            </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='usersFront/*'
          element={
            <SuspensedView>
              <UsersFrontPage />
            </SuspensedView>
          }
        />
        <Route
          path='clients/*'
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='historyTemplates/*'
          element={
            <SuspensedView>
              <ClientsHistoryTemplatesPage />
            </SuspensedView>
          }
        />
        <Route
          path='Vouchers/*'
          element={
            <SuspensedView>
              <VouchersPage />
            </SuspensedView>
          }
        />
        <Route
          path='Campaigns/*'
          element={
            <SuspensedView>
              <CampaignsPage />
            </SuspensedView>
          }
        />
        <Route
          path='Codes/*'
          element={
            <SuspensedView>
              <CodesPage />
            </SuspensedView>
          }
        />
        <Route
          path='EmailConfigs/*'
          element={
            <SuspensedView>
              <EmailConfigsPage />
            </SuspensedView>
          }
        />
        <Route
          path='EmailSended/*'
          element={
            <SuspensedView>
              <EmailsSendedPage />
            </SuspensedView>
          }
        />
        <Route
          path='SmsTemplates/*'
          element={
            <SuspensedView>
              <SmsTemplatePage />
            </SuspensedView>
          }
        />

        <Route
          path='NotificationsTemplate/*'
          element={
            <SuspensedView>
              <NotificationTemplate />
            </SuspensedView>
          }
        />
        <Route
          path='Country/*'
          element={
            <SuspensedView>
              <CountryPage />
            </SuspensedView>
          }
        />
        <Route
          path='SpaceTranslationsCountries/*'
          element={
            <SuspensedView>
              <CountryTranslationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='Segments/*'
          element={
            <SuspensedView>
              <ClientsSegmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
