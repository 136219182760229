import {AxiosResponse} from 'axios'
import { instance } from '../../../../configs/axiosClient'
import { ID } from '../../../../_metronic/helpers'
import { GetRequiredActionsSpaceGroupResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const DASHBOARD_SPACE_GROUP_URL = `${API_URL}Dashboard/SpaceGroup`

const getRequiredActionsSpaceGroupInfo = (query: ID): Promise<GetRequiredActionsSpaceGroupResponse> => {
  return instance
    .get(`${DASHBOARD_SPACE_GROUP_URL}/GetRequiredActionsSpaceGroupInfo/${query}`)
    .then((d: AxiosResponse<GetRequiredActionsSpaceGroupResponse>) => {
      return d.data
    })
}

const getRequiredActionsSpaceGroupCampaigns = (query: ID): Promise<GetRequiredActionsSpaceGroupResponse> => {
  return instance
    .get(`${DASHBOARD_SPACE_GROUP_URL}/GetRequiredActionsSpaceGroupCampaigns/${query}`)
    .then((d: AxiosResponse<GetRequiredActionsSpaceGroupResponse>) => {
      return d.data
    })
}

const getRequiredActionsSpaceGroupRecomendationCode = (query: ID): Promise<GetRequiredActionsSpaceGroupResponse> => {
  return instance
    .get(`${DASHBOARD_SPACE_GROUP_URL}/GetRequiredActionsSpaceGroupRecomendationCode/${query}`)
    .then((d: AxiosResponse<GetRequiredActionsSpaceGroupResponse>) => {
      return d.data
    })
}

export {
 getRequiredActionsSpaceGroupInfo,
 getRequiredActionsSpaceGroupCampaigns,
 getRequiredActionsSpaceGroupRecomendationCode
}
