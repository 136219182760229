import React from 'react'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'
import {EnumRoles, useAuth} from '../../../../app/modules/auth'
import { useSpaceGroup } from '../../../../app/hooks/useSpaceGroup'
import { Space } from '../../../../app/modules/spaces/spaces-list/core/_models'
import { getUniqueSpaceGroupIds } from '../../../helpers/getUniqueSpaceGroupIds'

export function AsideMenuSpaceGroup() {
  const {currentUser} = useAuth()
  const {spacesInGroup} = useSpaceGroup()
  
  return (
    <>
      <AsideMenuItem
        to='/dashboardSpaceGroup'
        title='Dashboard'
        fontIcon='bi-speedometer fs-2'
        bsTitle='Dashboard'
        className='py-2'
      />
       {currentUser && 
        currentUser.roles && 
        currentUser.roles.filter(
          (x) => x === EnumRoles.Estatisticas||
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos
        ) && <>
          <AsideMenuItem to='/statistics' title='Estatisticas' fontIcon='bi-percent fs-2' className='py-2' />
        </>}

       {currentUser && 
        currentUser.roles && 
        currentUser.roles.filter(
          (x) => x === EnumRoles.Objectivos||
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos
        ) && <>
          <AsideMenuItem to='/goals' title='Objectivos' fontIcon='bi-bullseye fs-2' className='py-2' />
        </>}

      
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.FaturacaoGerente ||
            x === EnumRoles.FaturacaoFuncionario
        ).length > 0 && (
          <>
            {spacesInGroup && spacesInGroup.length > 1 && <AsideMenuItem to='/spaces' title='Salões' fontIcon='bi-house fs-2' className='py-2' />}
            {spacesInGroup && spacesInGroup.length === 1 && <AsideMenuItem to='' enterSpace={true} space={{
              spaceId: spacesInGroup[0].value,
              name:spacesInGroup[0].label
            } as Space} title='Salão' fontIcon='bi-house fs-2' className='py-2' />}
            <AsideMenuItemWithSubMain
              to=''
              toArray={['/clients', '/historyTemplates', '/Segments']}
              title='Clientes'
              fontIcon='bi-people fs-2'
            >
              <AsideMenuItem
                to='/clients'
                title='Lista Clientes'
                bsTitle='Lista Clientes'
                hasBullet={true}
              />
              <AsideMenuItem to='/Segments' title='Segments' bsTitle='Segments' hasBullet={true} />
            </AsideMenuItemWithSubMain>
          </>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/galleriesPage', '/editSpace', '/editSocialMediaSpace', '/appconfigs']}
            title='APP'
            fontIcon='bi-pencil fs-2'
          >
            <AsideMenuItem to='/editSpace' title='Editar Dados' bsTitle='Editar Dados' hasBullet={true} />
            <AsideMenuItem to='/appconfigs' title='Configurações' bsTitle=' Configurações' hasBullet={true} />
            <AsideMenuItem
              to='/galleriesPage'
              title='Galerias'
              bsTitle='Galerias'
              hasBullet={true}
            />
            {/* <AsideMenuItem
              to='/portefolioPage'
              title='Portefolio'
              bsTitle='Portefolio'
              hasBullet={true}
            /> */}
            <AsideMenuItem
              to='/editSocialMediaSpace'
              title='Social Media'
              bsTitle='Social media'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
        {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Vouchers ||
            x === EnumRoles.Codes ||
            x === EnumRoles.Campanhas ||
            x === EnumRoles.Emails ||
            x === EnumRoles.Sms ||
            x === EnumRoles.Notificacoes
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/vouchers', '/codes', '/Codes/Offers', '/Codes/configs', '/NotificationsTemplate', 
              '/campaigns', '/Campaigns/CampaignsTypes', '/EmailSended', '/EmailConfigs', '/SmsTemplates']}
            title='Marketing'
            fontIcon='bi-speedometer fs-2'
          >
            <AsideMenuItem to='/vouchers' title='Vouchers' bsTitle='Vouchers' hasBullet={true} />
            <AsideMenuItemWithSubMain
              to=''
              toArray={['/codes', '/Codes/Offers', '/Codes/configs']}
              title='Codigos Promocionais'
              fontIcon='bi-arrow-right-short fs-2'
            >
              <AsideMenuItem
                to='/Codes/Offers'
                title='Gerir Ofertas de Códigos'
                bsTitle='Gerir Ofertas de Códigos'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/Codes/Configs'
                title='Activar / Configurar Codigos'
                bsTitle='Activar / Configurar Codigos'
                hasBullet={true}
              />
              <AsideMenuItem to='/codes' title='Lista Códigos' bsTitle='Lista Códigos' hasBullet={true} />
            </AsideMenuItemWithSubMain>
            <AsideMenuItemWithSubMain
              to=''
              toArray={['/campaigns', '/Campaigns/CampaignsTypes']}
              title='Campanhas/Ações'
              fontIcon='bi-arrow-right-short fs-2'
            >
              <AsideMenuItem to='/campaigns' title='Campanhas' bsTitle='Campanhas' hasBullet={true} />
              <AsideMenuItem
                to='/Campaigns/CampaignsTypes'
                title='Tipo Campanhas'
                bsTitle='Tipo Campanhas'
                hasBullet={true}
              />
            </AsideMenuItemWithSubMain>
            <AsideMenuItemWithSubMain
              to=''
              toArray={['/EmailSended', '/EmailConfigs']}
              title='Emails'
              fontIcon='bi-arrow-right-short fs-2'
            >
              <AsideMenuItem
                to='/EmailSended'
                title='Histórico de envio de emails'
                bsTitle='Histórico de envio de emails'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/EmailConfigs'
                title='Configs Email'
                bsTitle='Configs Email'
                hasBullet={true}
              />
            </AsideMenuItemWithSubMain>
            <AsideMenuItemWithSubMain
              to=''
              toArray={['/SmsTemplates']}
              title='Sms'
              fontIcon='bi-arrow-right-short fs-2'
            >
              <AsideMenuItem
                to='/SmsTemplates'
                title='Templates Sms'
                bsTitle='Templates Sms'
                hasBullet={true}
              />
            </AsideMenuItemWithSubMain>
            <AsideMenuItemWithSubMain
              to=''
              toArray={['/NotificationsTemplate']}
              title='Notificações'
              fontIcon='bi-arrow-right-short fs-2'
            >
              <AsideMenuItem
                to='/NotificationsTemplate'
                title='Templates Notificação'
                bsTitle='Templates Notificação'
                hasBullet={true}
              />
            </AsideMenuItemWithSubMain>
          </AsideMenuItemWithSubMain>
        )}
      {((currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos
        ).length > 0) ||getUniqueSpaceGroupIds(currentUser?.userSpaces).length > 1) && 
        <AsideMenuItem
          to='/usersFront'
          title='Users App'
          fontIcon='bi-person-lock fs-4'
          bsTitle='Users App'
          className='py-2'
        />}
      {((currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador 
        ).length > 0) ||getUniqueSpaceGroupIds(currentUser?.userSpaces).length > 1) && 
        <AsideMenuItem
          to='/sairEspaco'
          title='Voltar'
          fontIcon='bi-caret-left-fill fs-4'
          bsTitle='Voltar'
          className='py-2'
          isLogOutSpaceGroup={true}
        />}
      
    </>
  )
}
