const QUERIES = {
  SPACE_LIST: 'spaces-list',
  USERS_LIST: 'users-list',
  CAMPAIGNS_LIST: 'campaigns-list',
  CAMPAIGNS_CREATE_UPDATE: 'campaigns-create-update',
  CAMPAIGNS_TYPES_LIST: 'campaigns-types-list',
  CAMPAIGNS_TYPES_CREATE_UPDATE: 'campaigns-types-create-update',
  CAMPAIGN_COMUNICATION_REPORT: 'campaign-comunication-report',
  USERS_FRONT_LIST: 'users-front-list',
  VOUCHERS_LIST: 'vouchers-list',
  CLIENTS_LIST: 'clients-list',
  CLIENT_CREATE_UPDATE: 'client-create-update',
  CLIENT_HISTORY: 'client-history',
  CLIENT_SEGMENTS: 'client-segments',
  CODES_LIST: 'codes-list',
  CODES_CREATE_UPDATE: 'codes-create-update',
  CODES_OFFERS: 'codes-offers',
  COUNTRIES: 'countries',
  COUNTRIES_TRANSLATIONS: 'countries-translations',
  EMAILS: 'emails',
  EMAILS_SENDED: 'emails-sended',
  NOTIFICATIONS: 'notifications',
  SMS_TEMPLATES: 'sms-templates',
  Goals: 'goals',
};


export {QUERIES}
