import axios, {AxiosResponse} from 'axios'
import {CountriesQueryResponse, Country, CountrySpace, UpdateCountrySpaceRequest} from './_models'
import {instance} from '../../../../../../configs/axiosClient'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {SelectList} from '../../../../auth'

const API_URL = process.env.REACT_APP_API_URL
const COUNTRY_URL = `${API_URL}Country`
const COUNTRYSPACES_URL = `${API_URL}CountrySpaces`
const getCountries = (query: string): Promise<CountriesQueryResponse> => {
  return instance
    .get(`${COUNTRY_URL}/GetListCountriesSearch?${query}`)
    .then((d: AxiosResponse<CountriesQueryResponse>) => {
      return d.data
    })
}
const getCountriesBySpace = (query: string): Promise<CountriesQueryResponse> => {
  return instance
    .get(`${COUNTRY_URL}/GetListCountriesBySpaceSearch?${query}`)
    .then((d: AxiosResponse<CountriesQueryResponse>) => {
      return d.data
    })
}

const getCountryById = (id: ID): Promise<Country | undefined> => {
  return instance.get(`${COUNTRY_URL}/getCountryById/${id}`).then((d: AxiosResponse<Country>) => {
    return d.data
  })
}
const getCreateUpdateListSpacesCountries = (id: ID): Promise<SelectList[]> => {
  return instance
    .get(`${COUNTRY_URL}/getCreateUpdateListSpacesCountries/${id}`)
    .then((d: AxiosResponse<SelectList[]>) => {
      return d.data
    })
}

const createCountry = (user: Country): Promise<boolean> => {
  return instance
    .post(`${COUNTRY_URL}/CreateCountry`, user)
    .then((response: AxiosResponse<Response<boolean>>) => response.data)
    .then((response: Response<boolean>) => (response ? true : false))
}
const updateCountry = (user: Country): Promise<boolean> => {
  return instance
    .post(`${COUNTRY_URL}/UpdateCountry`, user)
    .then((response: AxiosResponse<Response<boolean>>) => response.data)
    .then((response: Response<boolean>) => (response ? true : false))
}

const deleteCountry = (Id: ID): Promise<void> => {
  return instance.delete(`${COUNTRY_URL}/DeleteCountry/${Id}`).then(() => {})
}

const deleteSelectedCountry = (CountriesIds: Array<ID>): Promise<void> => {
  const requests = CountriesIds.map((id) => instance.delete(`${COUNTRY_URL}/DeleteCountry/${id}`))
  return axios.all(requests).then(() => {})
}

const updateCountrySpace = (request: UpdateCountrySpaceRequest): Promise<boolean> => {
  return instance
    .post(`${COUNTRYSPACES_URL}/updateCountrySpace`, request)
    .then((response: AxiosResponse<Response<boolean>>) => response.data)
    .then((response: Response<boolean>) => (response ? true : false))
}
const removeCountryToSpace = (request: CountrySpace): Promise<void> => {
  return instance
    .delete(
      `${COUNTRYSPACES_URL}/RemoveCountryToSpace?spaceId=${request.spaceId}&countryId=${request.countryId}`
    )
    .then(() => {})
}

export {
  getCountries,
  getCountriesBySpace,
  getCountryById,
  getCreateUpdateListSpacesCountries,
  createCountry,
  updateCountry,
  deleteCountry,
  deleteSelectedCountry,
  updateCountrySpace,
  removeCountryToSpace,
}
