/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {AsideMenuDefault} from './AsideMenuDefault'
import {AsideMenuSpace} from './AsideMenuSpace'
import {AsideMenuSpaceGroup} from './AsideMenuSpaceGroup'

export function AsideMenuMain() {
  const {currentSpace, currentSpaceGroup} = useAuth()
  return currentSpace ? (
    <AsideMenuSpace></AsideMenuSpace>
  ) : currentSpaceGroup ? (
    <AsideMenuSpaceGroup></AsideMenuSpaceGroup>
  ) : (
    <AsideMenuDefault></AsideMenuDefault>
  )
}
