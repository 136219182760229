export const AdapterApiGetWayProxyResponseToObject = (resp) => {
  const {data} = resp
  const statusCode = data.statusCode
  const response = {
    ...resp,
    status: statusCode,
    data: data?.result ? data.result : null,
    errors: data?.errors,
  }
  return response
}
